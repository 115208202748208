export const getHomeUrl = (lang: string) => {
  if (lang === "en") return "/"
  return `/${lang}/`
}

export const getBlogUrl = (lang: string) => {
  if (lang === "en") return "/blog/"
  return `/${lang}/blog/`
}

export const getSearchUrl = (lang: string) => {
  if (lang === "en") return "/search/"
  return `/${lang}/search/`
}

export const getSearchArticlesUrl = (lang: string) => {
  if (lang === "en") return "/search/articles/"
  return `/${lang}/search/articles/`
}

export const getVerbUrl = (lang: string, verb: string) => {
  const sanitizeVerb = verb.replaceAll(" ", "-")

  if (lang === "en") return `/verbs/${sanitizeVerb}/`
  return `/${lang}/verbs/${sanitizeVerb}/`
}

export const getVersbUrl = (lang: string, page: number) => {
  if (page === 1) {
    if (lang === "en") return `/verbs/`
    return `/${lang}/verbs/`
  }

  if (lang === "en") return `/verbs/page/${page}/`
  return `/${lang}/verbs/page/${page}/`
}

export const getSearchAuthorsUrl = (lang: string) => {
  if (lang === "en") return "/search/authors/"
  return `/${lang}/search/authors/`
}

export const getSearchCategoriesUrl = (lang: string) => {
  if (lang === "en") return "/search/categories/"
  return `/${lang}/search/categories/`
}

export const getPopularUrl = (lang: string) => {
  if (lang === "en") return "/blog/popular/"
  return `/${lang}/blog/popular/`
}

export const getPrivacyUrl = (lang: string) => {
  if (lang === "en") return "/blog/legal/privacy/"
  if (lang === "fr") return "/fr/blog/legal/privacy/"
  if (lang === "es") return "/es/blog/legal/privacy/"
  if (lang === "pt") return "/pt/blog/legal/privacy/"
  if (lang === "it") return "/it/blog/legal/privacy/"

  throw new Error(`${lang} is an unknown language`)
}

export const getTermsUrl = (lang: string) => {
  if (lang === "en") return "/blog/legal/terms-of-service/"
  if (lang === "fr") return "/fr/blog/legal/terms-of-service/"
  if (lang === "es") return "/es/blog/legal/terms-of-service/"
  if (lang === "pt") return "/pt/blog/legal/terms-of-service/"
  if (lang === "it") return "/it/blog/legal/terms-of-service/"

  throw new Error(`${lang} is an unknown language`)
}

export const getCookiesUrl = (lang: string) => {
  if (lang === "en") return "/blog/legal/cookies/"
  if (lang === "fr") return "/fr/blog/legal/cookies/"
  if (lang === "es") return "/es/blog/legal/cookies/"
  if (lang === "pt") return "/pt/blog/legal/cookies/"
  if (lang === "it") return "/it/blog/legal/cookies/"

  throw new Error(`${lang} is an unknown language`)
}

export const normalizeI18nUrl = (lang: string, url: string) => {
  if (lang === "en") return `/${url}/`
  return `/${lang}/${url}/`
}

export const prefixByBlog = (lang: string, url: string) => {
  if (lang === "en") return `/blog/${url}/`
  return `/${lang}/blog/${url}/`
}

export const getAuthorUrl = (lang: string, username: string) => {
  return normalizeI18nUrl(lang, "@" + username)
}

export const getBookmarksUrl = (lang: string) => {
  if (lang === "en") return "/bookmarks/"
  if (lang === "fr") return "/fr/bookmarks/"
  if (lang === "es") return "/es/bookmarks/"
  if (lang === "pt") return "/pt/bookmarks/"
  if (lang === "it") return "/it/bookmarks/"

  throw new Error(`${lang} is an unknown language`)
}

export const getBookmarksLikesUrl = (lang: string) => {
  if (lang === "en") return "/bookmarks/likes/"
  if (lang === "fr") return "/fr/bookmarks/likes/"
  if (lang === "es") return "/es/bookmarks/likes/"
  if (lang === "pt") return "/pt/bookmarks/likes/"
  if (lang === "it") return "/it/bookmarks/likes/"

  throw new Error(`${lang} is an unknown language`)
}

export const getBookmarksFollowedUrl = (lang: string) => {
  if (lang === "en") return "/bookmarks/followed/"
  if (lang === "fr") return "/fr/bookmarks/followed/"
  if (lang === "es") return "/es/bookmarks/followed/"
  if (lang === "pt") return "/pt/bookmarks/followed/"
  if (lang === "it") return "/it/bookmarks/followed/"

  throw new Error(`${lang} is an unknown language`)
}
