import React from "react"
import { CustomIntlProvider } from "./src/components/CustomIntlProvider/CustomIntlProvider"
import { Notifications } from "./src/components/Notifications/Notifications"
import { LogoutModal } from "./src/components/LogoutModal/LogoutModal"
import { LoginModal } from "./src/components/LoginModal/LoginModal"
import { InfoModal } from "./src/components/InfoModal/InfoModal"

export default ({ element, props }) => {
  return (
    <CustomIntlProvider
      langKey={props.pageContext.language as string}
      translations={props.pageContext.translations}
    >
      <>
        {element}
        <Notifications />
        <LogoutModal />
        <LoginModal />
        <InfoModal />
      </>
    </CustomIntlProvider>
  )
}
