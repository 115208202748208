import React, { useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useLocation } from "@reach/router"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { closeLoginModal } from "../../utils/authModal"
import { useIntl } from "react-intl"

type Props = {}

export const InfoModal: React.FC<Props> = props => {
  const intl = useIntl()
  const location = useLocation()
  const isOpen = location.hash.includes("info=")
  const typeFromUrl = location.hash?.split("=")[1]
  const [type, setType] = useState("formal-high")

  useEffect(() => {
    if (typeFromUrl) setType(typeFromUrl)
  }, [typeFromUrl])

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => false}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 py-8 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-10 sm:py-20">
                <div className="absolute right-0 top-0">
                  <div className="p-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-slate-400 hover:text-slate-500 focus:outline-none"
                      onClick={() => closeLoginModal()}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>

                <div className="w-full">
                  <div className="mt-3 text-center sm:mt-0">
                    <div
                      className="text-xl font-display leading-6 font-medium text-slate-900"
                      id="modal-headline"
                    >
                      <FormattedMessage id={`info/modal/${type}/title`} />
                    </div>

                    <p className="text-slate-700 mt-4">
                      <FormattedMessage id={`info/modal/${type}/description`} />
                    </p>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
